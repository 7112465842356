<script setup lang="ts">
import { computed } from 'vue'
import type { Component } from 'vue'
import { useI18n } from 'vue-i18n'
import { Link } from '@inertiajs/vue3'
import LoadingSpinnerIcon from './Icons/LoadingSpinnerIcon.vue'

const { t } = useI18n()

const props = withDefaults(defineProps<{
    as?: 'link' | string | Component,
    size?: string,
    color?: string,
    colorVariant?: 'solid' | 'soft' | 'plain',
    rounded?: boolean,
    shadow?: boolean,
    loading?: boolean,
    loadingLabel?: 'processing' | 'loading',
    disabled?: boolean,
}>(), {
    as: 'button',
    size: 'md',
    color: 'indigo',
    colorVariant: 'solid',
    rounded: true,
    shadow: true,
    loading: false,
    loadingLabel: 'processing',
    disabled: false,
})

const isDisabled = computed(() => {
    if (props.loading || props.disabled) {
        return true
    }

    return false
})

const sizeStyles = computed(() => {
    switch (props.size) {
        case "xs":
            return ["px-2 py-1 text-xs", props.rounded && "rounded"]
        case "sm":
            if (props.colorVariant === 'plain') {
                return 'text-p2'
            }

            if (props.color === 'primary') {
                return ["px-3 py-2 text-p2", props.rounded && "rounded"]
            }

            return ["px-3 py-2 text-sm leading-4", props.rounded && "rounded-md"]
        case "md":
        default:
            if (props.colorVariant === 'plain') {
                return 'text-p1'
            }

            if (props.color === 'primary') {
                return ["px-3.5 py-2.5 text-p2", props.rounded && "rounded"]
            }

            return ["px-3.5 py-2.5 text-sm", props.rounded && "rounded-md"]
        case "lg":
            if (props.colorVariant === 'plain') {
                return 'text-p1'
            }

            if (props.color === 'primary') {
                return ["px-4 py-3 text-p1", props.rounded && "rounded"]
            }

            return ["px-4 py-2.5 text-base", props.rounded && "rounded-md"]
        case "xl":
            return ["px-6 py-3 text-base", props.rounded && "rounded-md"]
    }
})

const colorStyles = computed(() => {
    if (props.color === 'white') {
        return 'bg-white text-gray-700 border-gray-300 hover:bg-gray-50 disabled:bg-gray-100'
    }

    if (props.color === 'gray') {
        return props.colorVariant === 'solid'
            ? 'bg-gray-800 text-white border-transparent hover:bg-gray-700 disabled:bg-gray-600'
            : 'bg-gray-200 text-gray-600 border-transparent hover:bg-gray-300 disabled:bg-gray-200'
    }

    if (props.color === 'red') {
        return props.colorVariant === 'solid'
            ? 'border-transparent bg-red-600 text-white hover:bg-red-700 disabled:bg-gray-100 disabled:text-gray-300'
            : 'border-transparent bg-red-100 text-red-600 hover:bg-red-200 hover:text-red-800 disabled:bg-gray-100 disabled:text-gray-300'
    }

    if (props.color === 'green') {
        return props.colorVariant === 'solid'
            ? 'border-transparent bg-green-600 text-white hover:bg-green-700 disabled:bg-gray-100 disabled:text-gray-300'
            : 'border-transparent bg-green-100 text-green-600 hover:bg-green-200 hover:text-green-800 disabled:bg-gray-100 disabled:text-gray-300'
    }

    if (props.color === 'yellow') {
        return props.colorVariant === 'solid'
            ? 'border-transparent bg-yellow-600 text-white hover:bg-yellow-700 disabled:bg-gray-100 disabled:text-gray-300'
            : 'border-transparent bg-yellow-100 text-yellow-600 hover:bg-yellow-200 hover:text-yellow-800 disabled:bg-gray-100 disabled:text-gray-300'
    }

    if (props.color === 'blue') {
        return props.colorVariant === 'solid'
            ? 'border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:bg-blue-400'
            : 'border-transparent bg-blue-100 text-blue-600 hover:bg-blue-200 hover:text-blue-800 disabled:bg-gray-100 disabled:text-gray-300'
    }

    if (props.color === 'primary') {
        if (props.colorVariant === 'plain') {
            return ['border-0 text-primary-600 border-b hover:text-primary-800 active:text-primary-950 disabled:text-grayscale-700', props.loading ? 'border-transparent' : 'border-primary-600 disabled:border-transparent']
        }

        if (props.colorVariant === 'solid') {
            return 'border-0 bg-primary-600 text-white hover:bg-primary-800 active:bg-primary-950 disabled:bg-grayscale-100 disabled:text-grayscale-700'
        }

        return 'border-transparent bg-primary-100 text-primary-600 hover:bg-primary-200 hover:text-primary-800 disabled:bg-gray-100 disabled:text-gray-300'
    }

    return props.colorVariant === 'solid'
        ? 'border-transparent bg-indigo-600 text-white hover:bg-indigo-700 disabled:bg-indigo-400'
        : 'border-transparent bg-indigo-100 text-indigo-600 hover:bg-indigo-200 hover:text-indigo-800 disabled:bg-gray-100 disabled:text-gray-300'
})

const loadingIconSizeStyles = computed(() => {
    switch (props.size) {
        case "xs":
            return "-ml-0.5 mr-1.5 h-4 w-4"
        case "sm":
            return "-ml-1 mr-2 h-5 w-5"
        case "md":
        case "lg":
        case "xl":
        default:
            return "-ml-1 mr-3 h-5 w-5"
    }
})

const loadingLabelTranslation = computed(() => t(`components.button.${props.loadingLabel}`))
</script>

<template>
    <component
        :is="as === 'link' ? Link : as"
        :class="['inline-flex items-center justify-center border font-medium transition-colors', sizeStyles, colorStyles, shadow && 'shadow-sm', (loading || disabled) && 'cursor-not-allowed']"
        :disabled="isDisabled"
    >
        <template v-if="loading">
            <LoadingSpinnerIcon :class="['animate-spin', loadingIconSizeStyles]" />
            {{ loadingLabelTranslation }}
        </template>

        <slot v-else></slot>
    </component>
</template>
